.charts {
    .settings {
        padding: var(--space-box);
        display: grid;
        place-content: center;
        padding-top: 0;
    }
    .no-data-found {
        padding: var(--space-box);
        height: 400px;
        display: grid;
        place-content: center;
        opacity: 0.5;
    }
    .arrow-left, .arrow-right {
        height: 1.5rem;
        position: absolute;
        left: var(--space-default);
        top: 4rem;
        /* filter: grayscale(1); */
        &:hover {
            cursor: pointer;
        }
        &.inactive {
            filter: grayscale(1);
            pointer-events: none;
        }
    }
    .arrow-right {
        left: inherit;
        right: var(--space-default);
        transform: scaleX(-1);
    }
    &.loading {
        .VictoryContainer {
            opacity: 0.3;
        }
    }
    .VictoryContainer {
        transition: 0.2s opacity;
        height: inherit !important;
    }
}
