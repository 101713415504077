.dark-color-scheme {
    --color-white: #222;
    --color-black: #fff;
    --color-text: #fff;
    --color-grey: #ccc;
    --color-almost-white: #333;
    --color-grey-light: #151515;
    --color-grey-darker: #899598;
    --color-button: #000;
    --color-background: #555;
    --color-button-background: var(--color-black);
    --text-shadow-button: none;
    --color-scheme: dark;
    --border-button: 2px solid transparent;
    --box-shadow-form-elements: none;
    --icon-checked: url('/frontend/icons/checked_white.svg');
    --icon-chevron-down: url('/frontend/icons/streamline-micro-line/arrow-down_white.svg');
    --image-lettering-horizontal-one-line: url('/frontend/images/lettering/fasting-monster-horizontal-white.svg');
    --image-lettering-horizontal-two-lines: url('/frontend/images/lettering/fasting-monster-two-lines-horizontal-white.svg');
}

@media (prefers-color-scheme: dark) {
    body {
        @reuse .dark-color-scheme;
    }
}

body.force-dark-color-scheme {
    @reuse .dark-color-scheme;
}
