.fastings {
  .loading {
    opacity: 0.5;
    padding: calc(50vh - 10rem) 2rem;
    text-align: center;
  }
  .filter {
    text-align: center;
    padding-bottom: 1rem;
  }
  .load-more {
    padding: var(--space-box);
  }
  ul.fastings-list {
    padding: 0;
    li {
      list-style: none;
      padding: var(--space-box);
      background: var(--color-background);
      margin-bottom: 2px;

      small {
        font-size: normal;
        color: var(--color-grey);
      }
      header {
        display: flex;
        padding: 0;
        justify-content: space-between;
        .startDate {
          color: var(--color-grey);
        }
      }
    }

    .details {
      padding-bottom: 0.25rem;
    }
    .intakes {
      font-size: var(--font-size-small);
      color: #999;
      ul {
        padding: 0;
        li {
          padding: 0;
          margin: 0;
        }
      }
    }
    li.hidden {
      display: none;
    }
    .targeted {
      display: flex;
      justify-content: space-between;
      font-size: var(--font-size-small);
      color: #999;
    }
    details {
      padding-top: 0.25rem;
      > div {
        margin-top: var(--space-default);
        background: var(--color-grey-light);
        padding: var(--space-box);
        border-radius: var(--border-radius);
        line-height: 1.5;
      }
      summary {
        font-size: 0.8rem;
      }
    }
  }
  .left-and-right {
    display: grid;
    grid-template-columns: 1fr 3rem;
    grid-gap: 0.5rem;
  }
  .actions {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    img {
      height: 1.25rem;
      opacity: 0.5;
    }
  }
}
