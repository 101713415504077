.light-color-scheme {
    --color-white: #fff;
    --color-black: #000;
    --color-text: #111;
    --color-grey: #ccc;
    --color-almost-white: #eff0f0;
    --color-grey-light: #f2f2f2;
    --color-grey-darker: #899598;
    --color-button: var(--color-text);
    --color-button-background: var(--color-white);
    --color-border: var(--color-black);

    --color-primary: #d1fbff;
    --color-primary-alt: #00e9ff;
    --color-secondary: #b9ffec;

    --color-active-input: #0884ff;

    --color-link: var(--color-primary-alt);

    --color-red: #fc6c54;
    --color-red-light: #ffa0a0;

    --color-inactive: #ccc;
    --font-weight-bold: 600;

    --font-size-default: 16px;
    --font-size-small: 0.7rem;
    --font-size-large: 2.5rem;
    --font-family-monster: 'Paytone One', Arial;
    --font-family-monospace: ui-monospace, SFMono-Regular, SF Mono, Menlo,
        Consolas, Liberation Mono, monospace;
    --color-background: var(--color-grey-light);

    --space-box: 0.95rem 1rem;
    --space-box-large: 1.25rem 1.5rem;
    --space-box-small: 0.5rem 0.75rem;
    --space-default: 1rem;

    --border-radius: 15px;
    --border-radius-small: 8px;
    --transition-duration: 0.2s;

    --navi-bottom-height: 3.5rem;

    --border-default: 2px solid var(--color-border);
    --border-button: var(--border-default);

    --box-shadow-form-elements: 1px 1px 0px 2px var(--color-white) inset;
    --text-shadow-button: -1px -1px 1px rgb(255 255 255 / 70%);
    --color-scheme: inherit;

    --icon-checked: url('/frontend/icons/checked.svg');
    --icon-chevron-down: url('/frontend/icons/streamline-micro-line/arrow-down.svg');

    --image-lettering-horizontal-one-line: url('/frontend/images/lettering/fasting-monster-horizontal.svg');
    --image-lettering-horizontal-two-lines: url('/frontend/images/lettering/fasting-monster-two-lines-horizontal.svg');

    --font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

:root {
    @reuse .light-color-scheme;
    /* desktop + tablet */
    @media (min-width: 600px) and (orientation: portrait),
        (min-height: 900px) and (orientation: landscape) {
        --font-size-large: 4rem;
    }
}

@media (prefers-color-scheme: light) {
    body {
        @reuse .light-color-scheme;
    }
}

body.force-light-color-scheme {
    @reuse .light-color-scheme;
}
