.login {
    img[alt="Fasting Monster"] {
        height: 8rem;
        aspect-ratio: 1;
        object-fit: contain;
        margin: 0 auto;
        border: var(--border-default);
        border-radius: var(--border-radius);
    }
}

.login, .signup {
    a {
        margin: 1rem;
        text-align: center;
        display: block;
        text-decoration: none;
        color: #9f9f9f;
    }
}
