#app > #loading-indicator {
    height: 100%;
    width: 100%;
    /* flex: 1; */
    display: grid;
    place-content: center;
    opacity: 0.5;
    position: relative;
    text-align: center;
    .error-message {
        color: red;
        font-size: 0.8rem;
    }
}

#app {
    overflow-y: scroll;
    height: 100%;
    width: 100vw;
}

#app > div:not(.no-bottom-navi) > div:last-of-type:after {
    content: '';
    display: block;
    height: var(--navi-bottom-height);
}
#app .full-height-and-centered {
    display: flex;
    flex-direction: column;
    /* min-height: 100vh; */
    place-content: center;
    padding: var(--space-box);
    justify-content: space-between;
    &.no-space-between {
        justify-content: center;
    }
}
#app > div {
    background: var(--color-white);
    min-height: 100%;
    display: flex;
}

#app > div > *:first-child {
    flex: 1;
}

#bottom-navi {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: var(--navi-bottom-height);
    background: var(--color-background);
    border-top: var(--border-default);
    box-shadow: 0px 3px 0px -1px var(--color-white) inset;
    display: flex;
    gap: var(--space-default);
    place-content: center;

    img {
        height: 2rem;
    }
    a {
        color: transparent;
        text-decoration: none;
        padding: 0.5rem;
        display: flex;
        align-items: center;
    }
}

header {
    text-align: center;
    padding: var(--space-box);
    position: relative;
    h1 {
        font-size: 1.5rem;
        font-weight: 900;
        text-transform: uppercase;
        text-align: center;
        font-family: var(--font-family-headline);
        margin: 0.5rem 0;
    }
    .button-back {
        width: 2rem;
        position: absolute;
        filter: grayscale(1);
        &:hover {
            cursor: pointer;
        }
    }
}

/* Special Mobile view for Desktop devices */

@media (min-width: 600px) and (orientation: portrait),
    (min-height: 900px) and (orientation: landscape) {
    body {
        padding: 0px;
        box-shadow: none;
        background-color: var(--color-background);
        display: grid;
        place-content: center;
    }
    #app {
        margin: 0 auto;
        height: 760px;
        width: 400px;
        background: var(--color-white);
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        #bottom-navi {
            background: var(--color-white);
        }
        .full-height-and-centered {
            min-height: 100%;
        }
    }
    #app > div:not(.no-bottom-navi) > div:last-of-type:after {
        content: none;
        display: none;
    }
}
