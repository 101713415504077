.intake {
    .icons {
        padding: var(--space-box);
        padding-bottom: 0;
        margin-bottom: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0.25rem;
        row-gap: 0.5rem;

        img {
            width: 2rem;
            /* padding: 0.1rem; */
        }
    }
    li,
    .weight {
        list-style: none;
        padding: 0.5rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 2px solid transparent;
        &:hover {
            cursor: pointer;
            background: var(--color-grey-light);
            border-radius: var(--border-radius);
            border: var(--border-default);
        }
    }
    h6 {
        display: block;
        font-weight: normal;
        margin: 0;
        font-size: 0.8rem;
        line-height: 1.2em;
        padding-top: 0.5rem;
    }
    .weight-box {
        padding: var(--space-box);
    }
    .weight {
        display: grid;
        place-content: center;
        padding-bottom: var(--space-default);
        img {
            height: 3rem;
            margin: 0 auto;
            margin-top: var(--space-default);
        }
    }
}
