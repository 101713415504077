.settings {
    form {
        padding: var(--space-box);
    }
    .version {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        opacity: 0.5;
        font-size: 0.75rem;
    }
}
