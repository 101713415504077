.timer {
    background: var(--color-white);
    border-radius: var(--border-radius);
    row-gap: calc(1 * var(--space-default));

    .start-stop-button {
        display: grid;
        place-content: center;
        button {
            min-width: 8rem;
            margin: 0 auto;
        }
    }

    .breakfast-time {
        text-align: center;
        min-height: 3em;
        display: grid;
        place-content: center;
    }
    .starting-time {
        text-align: center;
        /* min-height: 6rem; */
        display: flex;
        justify-content: center;
        align-items: center;

        input[type='time'] {
            border: 0px;
            padding: 2px;
            font-size: 1rem;
            margin-left: 0.25rem;
            height: 1.3em;
            width: auto;
            background: none;
            color-scheme: var(--color-scheme);
        }
        input[type='datetime-local'] {
            display: none;
            font-size: 1rem;
            border: 0px;
            background: #eee;
            padding: 0.25rem;
            margin: 0.5rem auto;
        }
        &.show-date-time-input {
            display: grid;
            place-content: center;
            input[type='datetime-local'] {
                display: block;
            }
            input[type='time'] {
                display: none;
            }
        }
    }

    .circular-progress-bar {
        max-height: 12rem;
    }

    .timer-display {
        min-height: 5em;
        display: flex;
        align-items: center;
        justify-content: center;
        .time {
            text-align: center;
            font-size: 2rem;
            font-weight: bold;

            > span {
                &:before {
                    content: ':';
                }
            }
            > span:first-child:before {
                content: '';
            }
            > span:nth-child(2):before {
                content: ' ';
            }
            .seconds {
                color: var(--color-grey);
            }
        }
    }
}

.timer.active {
    .start-stop-button button {
        background-color: var(--color-secondary);
    }
}

.fasting-intervals {
    ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 3px;
        margin: 0;
        padding: 0;
        li {
            list-style: none;
            text-align: center;
            &:hover {
            }
        }
    }
    .unit {
        display: none;
    }
    li[data-interval='72'],
    li[data-interval='96'],
    li[data-interval='120'] {
        .unit {
            display: inline;
            margin-left: 0.5em;
        }
    }
}

.timer {
    --progress-bar-height: clamp(22rem, 50vh, 22rem);
}

.timer .circular-progress-bar > div,
.timer .circular-progress-bar-placeholder {
    transform: scale(0.75) translateY(-25%);
}

.timer .circular-progress-bar-placeholder {
    height: var(--progress-bar-height);
    width: 100%;
}

.timer .circular-progress-bar svg {
    height: var(--progress-bar-height);
}
@media (max-width: 1024px) and (orientation: landscape) {
    .fasting-intervals ul {
        grid-template-columns: repeat(6, 1fr);
    }
}
