@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  src: url("lato-v22-latin-regular.0fef7d9f.eot");
  src: local(""), url("lato-v22-latin-regular.0fef7d9f.eot#iefix") format("embedded-opentype"), url("lato-v22-latin-regular.e6d78644.woff2") format("woff2"), url("lato-v22-latin-regular.cbbef634.woff") format("woff"), url("lato-v22-latin-regular.823e310b.ttf") format("truetype"), url("lato-v22-latin-regular.d22ab101.svg#Lato") format("svg");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  src: url("lato-v22-latin-700.1ee483fe.eot");
  src: local(""), url("lato-v22-latin-700.1ee483fe.eot#iefix") format("embedded-opentype"), url("lato-v22-latin-700.22c81854.woff2") format("woff2"), url("lato-v22-latin-700.5389f765.woff") format("woff"), url("lato-v22-latin-700.95bf6c89.ttf") format("truetype"), url("lato-v22-latin-700.c68fab10.svg#Lato") format("svg");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  src: url("lato-v22-latin-900.a841e472.eot");
  src: local(""), url("lato-v22-latin-900.a841e472.eot#iefix") format("embedded-opentype"), url("lato-v22-latin-900.b7aab9b7.woff2") format("woff2"), url("lato-v22-latin-900.7e33f226.woff") format("woff"), url("lato-v22-latin-900.b9f16c75.ttf") format("truetype"), url("lato-v22-latin-900.73a0fcc5.svg#Lato") format("svg");
}

@font-face {
  font-family: Paytone One;
  font-style: normal;
  font-weight: 400;
  src: url("paytone-one-v16-latin-regular.3ca38c18.eot");
  src: local(""), url("paytone-one-v16-latin-regular.3ca38c18.eot#iefix") format("embedded-opentype"), url("paytone-one-v16-latin-regular.150113f2.woff2") format("woff2"), url("paytone-one-v16-latin-regular.a7485a9e.woff") format("woff"), url("paytone-one-v16-latin-regular.84541a5e.ttf") format("truetype"), url("paytone-one-v16-latin-regular.01242800.svg#PaytoneOne") format("svg");
}

.light-color-scheme {
  --color-white: #fff;
  --color-black: #000;
  --color-text: #111;
  --color-grey: #ccc;
  --color-almost-white: #eff0f0;
  --color-grey-light: #f2f2f2;
  --color-grey-darker: #899598;
  --color-button: var(--color-text);
  --color-button-background: var(--color-white);
  --color-border: var(--color-black);
  --color-primary: #d1fbff;
  --color-primary-alt: #00e9ff;
  --color-secondary: #b9ffec;
  --color-active-input: #0884ff;
  --color-link: var(--color-primary-alt);
  --color-red: #fc6c54;
  --color-red-light: #ffa0a0;
  --color-inactive: #ccc;
  --font-weight-bold: 600;
  --font-size-default: 16px;
  --font-size-small: .7rem;
  --font-size-large: 2.5rem;
  --font-family-monster: "Paytone One", Arial;
  --font-family-monospace: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  --color-background: var(--color-grey-light);
  --space-box: .95rem 1rem;
  --space-box-large: 1.25rem 1.5rem;
  --space-box-small: .5rem .75rem;
  --space-default: 1rem;
  --border-radius: 15px;
  --border-radius-small: 8px;
  --transition-duration: .2s;
  --navi-bottom-height: 3.5rem;
  --border-default: 2px solid var(--color-border);
  --border-button: var(--border-default);
  --box-shadow-form-elements: 1px 1px 0px 2px var(--color-white) inset;
  --text-shadow-button: -1px -1px 1px #ffffffb3;
  --color-scheme: inherit;
  --icon-checked: url("checked.95d10ab3.svg");
  --icon-chevron-down: url("arrow-down.b8b2d2e2.svg");
  --image-lettering-horizontal-one-line: url("fasting-monster-horizontal.5bdd7565.svg");
  --image-lettering-horizontal-two-lines: url("fasting-monster-two-lines-horizontal.0d418b1c.svg");
  --font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

:root {
  --color-white: #fff;
  --color-black: #000;
  --color-text: #111;
  --color-grey: #ccc;
  --color-almost-white: #eff0f0;
  --color-grey-light: #f2f2f2;
  --color-grey-darker: #899598;
  --color-button: var(--color-text);
  --color-button-background: var(--color-white);
  --color-border: var(--color-black);
  --color-primary: #d1fbff;
  --color-primary-alt: #00e9ff;
  --color-secondary: #b9ffec;
  --color-active-input: #0884ff;
  --color-link: var(--color-primary-alt);
  --color-red: #fc6c54;
  --color-red-light: #ffa0a0;
  --color-inactive: #ccc;
  --font-weight-bold: 600;
  --font-size-default: 16px;
  --font-size-small: .7rem;
  --font-size-large: 2.5rem;
  --font-family-monster: "Paytone One", Arial;
  --font-family-monospace: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  --color-background: var(--color-grey-light);
  --space-box: .95rem 1rem;
  --space-box-large: 1.25rem 1.5rem;
  --space-box-small: .5rem .75rem;
  --space-default: 1rem;
  --border-radius: 15px;
  --border-radius-small: 8px;
  --transition-duration: .2s;
  --navi-bottom-height: 3.5rem;
  --border-default: 2px solid var(--color-border);
  --border-button: var(--border-default);
  --box-shadow-form-elements: 1px 1px 0px 2px var(--color-white) inset;
  --text-shadow-button: -1px -1px 1px #ffffffb3;
  --color-scheme: inherit;
  --icon-checked: url("checked.95d10ab3.svg");
  --icon-chevron-down: url("arrow-down.b8b2d2e2.svg");
  --image-lettering-horizontal-one-line: url("fasting-monster-horizontal.5bdd7565.svg");
  --image-lettering-horizontal-two-lines: url("fasting-monster-two-lines-horizontal.0d418b1c.svg");
  --font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

@media (min-width: 600px) and (orientation: portrait), (min-height: 900px) and (orientation: landscape) {
  :root {
    --font-size-large: 4rem;
  }
}

@media (prefers-color-scheme: light) {
  body {
    --color-white: #fff;
    --color-black: #000;
    --color-text: #111;
    --color-grey: #ccc;
    --color-almost-white: #eff0f0;
    --color-grey-light: #f2f2f2;
    --color-grey-darker: #899598;
    --color-button: var(--color-text);
    --color-button-background: var(--color-white);
    --color-border: var(--color-black);
    --color-primary: #d1fbff;
    --color-primary-alt: #00e9ff;
    --color-secondary: #b9ffec;
    --color-active-input: #0884ff;
    --color-link: var(--color-primary-alt);
    --color-red: #fc6c54;
    --color-red-light: #ffa0a0;
    --color-inactive: #ccc;
    --font-weight-bold: 600;
    --font-size-default: 16px;
    --font-size-small: .7rem;
    --font-size-large: 2.5rem;
    --font-family-monster: "Paytone One", Arial;
    --font-family-monospace: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
    --color-background: var(--color-grey-light);
    --space-box: .95rem 1rem;
    --space-box-large: 1.25rem 1.5rem;
    --space-box-small: .5rem .75rem;
    --space-default: 1rem;
    --border-radius: 15px;
    --border-radius-small: 8px;
    --transition-duration: .2s;
    --navi-bottom-height: 3.5rem;
    --border-default: 2px solid var(--color-border);
    --border-button: var(--border-default);
    --box-shadow-form-elements: 1px 1px 0px 2px var(--color-white) inset;
    --text-shadow-button: -1px -1px 1px #ffffffb3;
    --color-scheme: inherit;
    --icon-checked: url("checked.95d10ab3.svg");
    --icon-chevron-down: url("arrow-down.b8b2d2e2.svg");
    --image-lettering-horizontal-one-line: url("fasting-monster-horizontal.5bdd7565.svg");
    --image-lettering-horizontal-two-lines: url("fasting-monster-two-lines-horizontal.0d418b1c.svg");
    --font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  }
}

body.force-light-color-scheme {
  --color-white: #fff;
  --color-black: #000;
  --color-text: #111;
  --color-grey: #ccc;
  --color-almost-white: #eff0f0;
  --color-grey-light: #f2f2f2;
  --color-grey-darker: #899598;
  --color-button: var(--color-text);
  --color-button-background: var(--color-white);
  --color-border: var(--color-black);
  --color-primary: #d1fbff;
  --color-primary-alt: #00e9ff;
  --color-secondary: #b9ffec;
  --color-active-input: #0884ff;
  --color-link: var(--color-primary-alt);
  --color-red: #fc6c54;
  --color-red-light: #ffa0a0;
  --color-inactive: #ccc;
  --font-weight-bold: 600;
  --font-size-default: 16px;
  --font-size-small: .7rem;
  --font-size-large: 2.5rem;
  --font-family-monster: "Paytone One", Arial;
  --font-family-monospace: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  --color-background: var(--color-grey-light);
  --space-box: .95rem 1rem;
  --space-box-large: 1.25rem 1.5rem;
  --space-box-small: .5rem .75rem;
  --space-default: 1rem;
  --border-radius: 15px;
  --border-radius-small: 8px;
  --transition-duration: .2s;
  --navi-bottom-height: 3.5rem;
  --border-default: 2px solid var(--color-border);
  --border-button: var(--border-default);
  --box-shadow-form-elements: 1px 1px 0px 2px var(--color-white) inset;
  --text-shadow-button: -1px -1px 1px #ffffffb3;
  --color-scheme: inherit;
  --icon-checked: url("checked.95d10ab3.svg");
  --icon-chevron-down: url("arrow-down.b8b2d2e2.svg");
  --image-lettering-horizontal-one-line: url("fasting-monster-horizontal.5bdd7565.svg");
  --image-lettering-horizontal-two-lines: url("fasting-monster-two-lines-horizontal.0d418b1c.svg");
  --font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

html {
  font-size: var(--font-size-default);
}

body {
  font-family: var(--font-family);
  color: var(--color-text);
  background: var(--color-white);
  line-height: 1.25em;
}

*, :after, :before {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
}

a {
  color: var(--color-link);
}

.text-center {
  text-align: center;
}

.small-and-gentle-hint {
  color: var(--color-grey);
  text-align: center;
  padding: var(--space-box);
  font-size: .8rem;
}

.dark-color-scheme {
  --color-white: #222;
  --color-black: #fff;
  --color-text: #fff;
  --color-grey: #ccc;
  --color-almost-white: #333;
  --color-grey-light: #151515;
  --color-grey-darker: #899598;
  --color-button: #000;
  --color-background: #555;
  --color-button-background: var(--color-black);
  --text-shadow-button: none;
  --color-scheme: dark;
  --border-button: 2px solid transparent;
  --box-shadow-form-elements: none;
  --icon-checked: url("checked_white.d76e7350.svg");
  --icon-chevron-down: url("arrow-down_white.a43a7f9b.svg");
  --image-lettering-horizontal-one-line: url("fasting-monster-horizontal-white.c1e18eba.svg");
  --image-lettering-horizontal-two-lines: url("fasting-monster-two-lines-horizontal-white.554a45d6.svg");
}

@media (prefers-color-scheme: dark) {
  body {
    --color-white: #222;
    --color-black: #fff;
    --color-text: #fff;
    --color-grey: #ccc;
    --color-almost-white: #333;
    --color-grey-light: #151515;
    --color-grey-darker: #899598;
    --color-button: #000;
    --color-background: #555;
    --color-button-background: var(--color-black);
    --text-shadow-button: none;
    --color-scheme: dark;
    --border-button: 2px solid transparent;
    --box-shadow-form-elements: none;
    --icon-checked: url("checked_white.d76e7350.svg");
    --icon-chevron-down: url("arrow-down_white.a43a7f9b.svg");
    --image-lettering-horizontal-one-line: url("fasting-monster-horizontal-white.c1e18eba.svg");
    --image-lettering-horizontal-two-lines: url("fasting-monster-two-lines-horizontal-white.554a45d6.svg");
  }
}

body.force-dark-color-scheme {
  --color-white: #222;
  --color-black: #fff;
  --color-text: #fff;
  --color-grey: #ccc;
  --color-almost-white: #333;
  --color-grey-light: #151515;
  --color-grey-darker: #899598;
  --color-button: #000;
  --color-background: #555;
  --color-button-background: var(--color-black);
  --text-shadow-button: none;
  --color-scheme: dark;
  --border-button: 2px solid transparent;
  --box-shadow-form-elements: none;
  --icon-checked: url("checked_white.d76e7350.svg");
  --icon-chevron-down: url("arrow-down_white.a43a7f9b.svg");
  --image-lettering-horizontal-one-line: url("fasting-monster-horizontal-white.c1e18eba.svg");
  --image-lettering-horizontal-two-lines: url("fasting-monster-two-lines-horizontal-white.554a45d6.svg");
}

.button {
  border: var(--border-button);
  border-radius: var(--border-radius);
  text-transform: uppercase;
  transition: background-color var(--transition-duration);
  background: var(--color-button-background);
  box-shadow: var(--box-shadow-form-elements);
  color: var(--color-button);
  text-shadow: var(--text-shadow-button);
  padding: .5rem 1rem;
  font-size: .9rem;
  font-weight: 900;
  transition: transform .1s;
  display: inline-block;
}

.button:hover, .button:active {
  background: var(--color-primary);
  cursor: pointer;
}

.button:hover {
  text-shadow: 1px 1px #fff;
  transform: translate(-1px, -1px);
  box-shadow: 2px 2px #0003;
}

.button.active, .button.primary {
  background-color: var(--color-secondary);
}

.button.secondary {
  background: var(--color-primary);
}

.button.small {
  padding: .25rem .75rem;
  font-size: .8rem;
}

a.button {
  text-decoration: none;
}

form.form {
  background: var(--color-background);
  padding: var(--space-box);
  border: var(--border-default);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-form-elements);
}

form.form input {
  padding: var(--space-box-small);
  border: var(--border-default);
  border-radius: var(--border-radius-small);
  font-size: 1rem;
  font-family: var(--font-family);
  width: 100%;
  color: var(--color-text);
  background: var(--color-white);
  color-scheme: var(--color-scheme);
}

form.form > label {
  padding-bottom: var(--space-default);
  display: block;
}

form.form label > div {
  padding-bottom: calc(.5 * var(--space-default));
}

form.form button[role], form.form a.button {
  border-radius: var(--border-radius-small);
  width: 100%;
  color: var(--color-button);
  text-align: center;
  margin-top: .5rem;
}

form.form button[role="submit"] {
  background-color: var(--color-secondary);
}

form.form a.button.delete {
  background-color: var(--color-red-light);
  color: #6f0000;
  width: 100%;
  text-align: center;
  border-radius: var(--border-radius-small);
  display: block;
}

form.form .form-message {
  padding: var(--space-default) 0;
}

form.form .form-message.error {
  color: var(--color-red);
}

form.form .form-message {
  text-align: center;
  font-weight: bold;
}

form.form .form-message:last-child {
  padding-bottom: 0;
}

form.form .form-message:first-child {
  padding-top: 0;
}

form.form a.close {
  justify-content: flex-end;
  display: flex;
}

form.form a.close img {
  width: 1.5rem;
  box-shadow: 2px 2px 0px var(--color-white);
  background: #fff;
  border-radius: 50%;
  transition: transform .1s;
}

form.form a.close img:hover {
  box-shadow: none;
  transform: translate(1px, 1px);
}

form.form .actions {
  text-align: center;
  padding: var(--space-box);
  display: block;
}

form.form label.with-checkbox {
  justify-content: space-between;
  display: flex;
}

form.form input[type="checkbox"] {
  height: 1.25rem;
  width: 1.25rem;
  background: var(--color-white);
  color: currentColor;
  appearance: none;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: .15em solid;
  border-radius: .15em;
  margin: 0;
  padding: 0;
  transform: scale(1.1);
}

form.form input[type="checkbox"]:hover {
  cursor: pointer;
}

form.form input[type="checkbox"]:before {
  content: "✓";
  color: #0000;
  width: 1em;
  text-align: center;
  font-size: .85rem;
  font-weight: bold;
  line-height: 1em;
  display: block;
}

form.form input[type="checkbox"]:checked:before {
  color: currentColor;
}

form.form select, select.select {
  border: var(--border-default);
  background: var(--color-white);
  border-radius: var(--border-radius-small);
  padding: var(--space-box-small);
  appearance: none;
  text-align: center;
  background: var(--icon-chevron-down) 90% / min(12%, 1rem) no-repeat var(--color-white);
  color: var(--color-text);
  padding-right: 3rem;
  font-size: 1rem;
}

form.form select {
  width: 100%;
}

form.form textarea, textarea.textarea {
  border: var(--border-default);
  background: var(--color-white);
  border-radius: var(--border-radius-small);
  padding: var(--space-box-small);
  width: 100%;
  color: var(--color-text);
  font-size: 1rem;
}

textarea:focus, input:focus, button[type="submit"] {
  outline-color: var(--color-active-input);
}



#app > #loading-indicator {
  height: 100%;
  width: 100%;
  opacity: .5;
  text-align: center;
  place-content: center;
  display: grid;
  position: relative;
}

#app > #loading-indicator .error-message {
  color: red;
  font-size: .8rem;
}

#app {
  height: 100%;
  width: 100vw;
  overflow-y: scroll;
}

#app > div:not(.no-bottom-navi) > div:last-of-type:after {
  content: "";
  height: var(--navi-bottom-height);
  display: block;
}

#app .full-height-and-centered {
  padding: var(--space-box);
  flex-direction: column;
  place-content: center space-between;
  display: flex;
}

#app .full-height-and-centered.no-space-between {
  justify-content: center;
}

#app > div {
  background: var(--color-white);
  min-height: 100%;
  display: flex;
}

#app > div > :first-child {
  flex: 1;
}

#bottom-navi {
  width: 100%;
  min-height: var(--navi-bottom-height);
  background: var(--color-background);
  border-top: var(--border-default);
  box-shadow: 0px 3px 0px -1px var(--color-white) inset;
  gap: var(--space-default);
  place-content: center;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
}

#bottom-navi img {
  height: 2rem;
}

#bottom-navi a {
  color: #0000;
  align-items: center;
  padding: .5rem;
  text-decoration: none;
  display: flex;
}

header {
  text-align: center;
  padding: var(--space-box);
  position: relative;
}

header h1 {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 900;
  font-family: var(--font-family-headline);
  margin: .5rem 0;
}

header .button-back {
  width: 2rem;
  filter: grayscale();
  position: absolute;
}

header .button-back:hover {
  cursor: pointer;
}

@media (min-width: 600px) and (orientation: portrait), (min-height: 900px) and (orientation: landscape) {
  body {
    box-shadow: none;
    background-color: var(--color-background);
    place-content: center;
    padding: 0;
    display: grid;
  }

  #app {
    height: 760px;
    width: 400px;
    background: var(--color-white);
    margin: 0 auto;
    box-shadow: 0 0 30px #0000001a;
  }

  #app #bottom-navi {
    background: var(--color-white);
  }

  #app .full-height-and-centered {
    min-height: 100%;
  }

  #app > div:not(.no-bottom-navi) > div:last-of-type:after {
    content: none;
    display: none;
  }
}

.fastings .loading {
  opacity: .5;
  text-align: center;
  padding: calc(50vh - 10rem) 2rem;
}

.fastings .filter {
  text-align: center;
  padding-bottom: 1rem;
}

.fastings .load-more {
  padding: var(--space-box);
}

.fastings ul.fastings-list {
  padding: 0;
}

.fastings ul.fastings-list li {
  padding: var(--space-box);
  background: var(--color-background);
  margin-bottom: 2px;
  list-style: none;
}

.fastings ul.fastings-list li small {
  font-size: normal;
  color: var(--color-grey);
}

.fastings ul.fastings-list li header {
  justify-content: space-between;
  padding: 0;
  display: flex;
}

.fastings ul.fastings-list li header .startDate {
  color: var(--color-grey);
}

.fastings ul.fastings-list .details {
  padding-bottom: .25rem;
}

.fastings ul.fastings-list .intakes {
  font-size: var(--font-size-small);
  color: #999;
}

.fastings ul.fastings-list .intakes ul {
  padding: 0;
}

.fastings ul.fastings-list .intakes ul li {
  margin: 0;
  padding: 0;
}

.fastings ul.fastings-list li.hidden {
  display: none;
}

.fastings ul.fastings-list .targeted {
  font-size: var(--font-size-small);
  color: #999;
  justify-content: space-between;
  display: flex;
}

.fastings ul.fastings-list details {
  padding-top: .25rem;
}

.fastings ul.fastings-list details > div {
  margin-top: var(--space-default);
  background: var(--color-grey-light);
  padding: var(--space-box);
  border-radius: var(--border-radius);
  line-height: 1.5;
}

.fastings ul.fastings-list details summary {
  font-size: .8rem;
}

.fastings .left-and-right {
  grid-gap: .5rem;
  grid-template-columns: 1fr 3rem;
  display: grid;
}

.fastings .actions {
  justify-content: center;
  display: flex;
}

.fastings .actions img {
  height: 1.25rem;
  opacity: .5;
}

.login img[alt="Fasting Monster"] {
  height: 8rem;
  aspect-ratio: 1;
  object-fit: contain;
  border: var(--border-default);
  border-radius: var(--border-radius);
  margin: 0 auto;
}

.login a, .signup a {
  text-align: center;
  color: #9f9f9f;
  margin: 1rem;
  text-decoration: none;
  display: block;
}

.settings form {
  padding: var(--space-box);
}

.settings .version {
  opacity: .5;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: .75rem;
  display: flex;
}

.timer {
  background: var(--color-white);
  border-radius: var(--border-radius);
  row-gap: calc(1 * var(--space-default));
}

.timer .start-stop-button {
  place-content: center;
  display: grid;
}

.timer .start-stop-button button {
  min-width: 8rem;
  margin: 0 auto;
}

.timer .breakfast-time {
  text-align: center;
  min-height: 3em;
  place-content: center;
  display: grid;
}

.timer .starting-time {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.timer .starting-time input[type="time"] {
  height: 1.3em;
  width: auto;
  color-scheme: var(--color-scheme);
  background: none;
  border: 0;
  margin-left: .25rem;
  padding: 2px;
  font-size: 1rem;
}

.timer .starting-time input[type="datetime-local"] {
  background: #eee;
  border: 0;
  margin: .5rem auto;
  padding: .25rem;
  font-size: 1rem;
  display: none;
}

.timer .starting-time.show-date-time-input {
  place-content: center;
  display: grid;
}

.timer .starting-time.show-date-time-input input[type="datetime-local"] {
  display: block;
}

.timer .starting-time.show-date-time-input input[type="time"] {
  display: none;
}

.timer .circular-progress-bar {
  max-height: 12rem;
}

.timer .timer-display {
  min-height: 5em;
  justify-content: center;
  align-items: center;
  display: flex;
}

.timer .timer-display .time {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
}

.timer .timer-display .time > span:before {
  content: ":";
}

.timer .timer-display .time > span:first-child:before {
  content: "";
}

.timer .timer-display .time > span:nth-child(2):before {
  content: " ";
}

.timer .timer-display .time .seconds {
  color: var(--color-grey);
}

.timer.active .start-stop-button button {
  background-color: var(--color-secondary);
}

.fasting-intervals ul {
  grid-template-columns: repeat(3, 1fr);
  gap: 3px;
  margin: 0;
  padding: 0;
  display: grid;
}

.fasting-intervals ul li {
  text-align: center;
  list-style: none;
}

.fasting-intervals .unit {
  display: none;
}

.fasting-intervals li[data-interval="72"] .unit, .fasting-intervals li[data-interval="96"] .unit, .fasting-intervals li[data-interval="120"] .unit {
  margin-left: .5em;
  display: inline;
}

.timer {
  --progress-bar-height: clamp(22rem, 50vh, 22rem);
}

.timer .circular-progress-bar > div, .timer .circular-progress-bar-placeholder {
  transform: scale(.75)translateY(-25%);
}

.timer .circular-progress-bar-placeholder {
  height: var(--progress-bar-height);
  width: 100%;
}

.timer .circular-progress-bar svg {
  height: var(--progress-bar-height);
}

@media (max-width: 1024px) and (orientation: landscape) {
  .fasting-intervals ul {
    grid-template-columns: repeat(6, 1fr);
  }
}

.signup img[alt="Fasting Monster"] {
  height: 10rem;
  padding: var(--space-box);
  margin: 0 auto;
  display: block;
}

.fasting > .data {
  padding: var(--space-box);
}

.intake .icons {
  padding: var(--space-box);
  grid-template-columns: repeat(3, 1fr);
  gap: .5rem .25rem;
  margin-bottom: 0;
  padding-bottom: 0;
  display: grid;
}

.intake .icons img {
  width: 2rem;
}

.intake li, .intake .weight {
  text-align: center;
  border: 2px solid #0000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  list-style: none;
  display: flex;
}

.intake li:hover, .intake .weight:hover {
  cursor: pointer;
  background: var(--color-grey-light);
  border-radius: var(--border-radius);
  border: var(--border-default);
}

.intake h6 {
  margin: 0;
  padding-top: .5rem;
  font-size: .8rem;
  font-weight: normal;
  line-height: 1.2em;
  display: block;
}

.intake .weight-box {
  padding: var(--space-box);
}

.intake .weight {
  padding-bottom: var(--space-default);
  place-content: center;
  display: grid;
}

.intake .weight img {
  height: 3rem;
  margin: 0 auto;
  margin-top: var(--space-default);
}

.charts .settings {
  padding: var(--space-box);
  place-content: center;
  padding-top: 0;
  display: grid;
}

.charts .no-data-found {
  padding: var(--space-box);
  height: 400px;
  opacity: .5;
  place-content: center;
  display: grid;
}

.charts .arrow-left, .charts .arrow-right {
  height: 1.5rem;
  left: var(--space-default);
  position: absolute;
  top: 4rem;
}

.charts .arrow-left:hover, .charts .arrow-right:hover {
  cursor: pointer;
}

.charts .arrow-left.inactive, .charts .arrow-right.inactive {
  filter: grayscale();
  pointer-events: none;
}

.charts .arrow-right {
  left: inherit;
  right: var(--space-default);
  transform: scaleX(-1);
}

.charts.loading .VictoryContainer {
  opacity: .3;
}

.charts .VictoryContainer {
  transition: opacity .2s;
  height: inherit !important;
}

/*# sourceMappingURL=app.24535394.css.map */
