.button {
    border: var(--border-button);
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius);
    font-weight: 900;
    text-transform: uppercase;
    transition: background-color var(--transition-duration);
    font-size: 0.9rem;
    background: var(--color-button-background);
    box-shadow: var(--box-shadow-form-elements);
    color: var(--color-button);
    display: inline-block;
    transition: transform 0.1s;
    text-shadow: var(--text-shadow-button);
    &:hover,
    &:active {
        background: var(--color-primary);
        cursor: pointer;
    }
    /* only for mouse */
    &:hover {
        transform: translate(-1px, -1px);
        box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
        text-shadow: 1px 1px 0px #fff;
    }
    &.active,
    &.primary {
        background-color: var(--color-secondary);
    }
    &.secondary {
        background: var(--color-primary);
    }
    &.small {
        font-size: 0.8rem;
        padding: 0.25rem 0.75rem;
    }
}
a.button {
    text-decoration: none;
}

form.form {
    background: var(--color-background);
    padding: var(--space-box);
    border: var(--border-default);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-form-elements);
    input {
        padding: var(--space-box-small);
        border: var(--border-default);
        border-radius: var(--border-radius-small);
        font-size: 1rem;
        font-family: var(--font-family);
        width: 100%;
        color: var(--color-text);
        background: var(--color-white);
        color-scheme: var(--color-scheme);
        /* font-family: 'IBM Plex Mono', monospace; */
    }
    > label {
        padding-bottom: var(--space-default);
        display: block;
    }
    label > div {
        padding-bottom: calc(0.5 * var(--space-default));
    }
    button[role],
    a.button {
        margin-top: 0.5rem;
        border-radius: var(--border-radius-small);
        width: 100%;
        /* color: #0059ff; */
        color: var(--color-button);
        text-align: center;
    }
    button[role='submit'] {
        background-color: var(--color-secondary);
    }
    a.button.delete {
        background-color: var(--color-red-light);
        color: #6f0000;
        width: 100%;
        text-align: center;
        display: block;
        border-radius: var(--border-radius-small);
    }
    .form-message {
        padding: var(--space-default) 0;
        &.error {
            color: var(--color-red);
        }
        font-weight: bold;
        text-align: center;
        &:last-child {
            padding-bottom: 0;
        }
        &:first-child {
            padding-top: 0;
        }
    }
    a.close {
        display: flex;
        justify-content: flex-end;
        img {
            width: 1.5rem;
            background: #fff;
            border-radius: 50%;
            box-shadow: 2px 2px 0px var(--color-white);
            transition: transform 0.1s;
            &:hover {
                box-shadow: none;
                transform: translate(1px, 1px);
            }
        }
    }
    .actions {
        display: block;
        text-align: center;
        padding: var(--space-box);
    }
    label.with-checkbox {
        display: flex;
        justify-content: space-between;
    }
    /* https://css-tricks.com/the-checkbox-hack/ */
    input[type='checkbox'] {
        height: 1.25rem;
        width: 1.25rem;
        margin: 0;
        padding: 0;
        border: 0;
        background: var(--color-white);
        color: currentColor;
        -webkit-appearance: none;
        appearance: none;
        border: 1px;
        font: inherit;
        color: currentColor;
        width: 1.15em;
        height: 1.15em;
        border: 0.15em solid currentColor;
        border-radius: 0.15em;
        transform: scale(1.1);
        &:hover {
            cursor: pointer;
        }
    }
    input[type='checkbox']:before {
        content: '✓';

        color: transparent;
        font-size: 0.85rem;
        line-height: 1em;
        display: block;
        width: 1em;
        text-align: center;
        font-weight: bold;
    }
    input[type='checkbox']:checked:before {
        color: currentColor;
    }
}

form.form select,
select.select {
    border: var(--border-default);
    background: var(--color-white);
    border-radius: var(--border-radius-small);
    font-size: 1rem;
    padding: var(--space-box-small);
    appearance: none;
    /* padding-right: 1rem; */
    text-align: center;
    /* 96% / 15% */
    background: var(--icon-chevron-down) 90% / min(12%, 1rem) no-repeat
        var(--color-white);
    padding-right: 3rem;
    color: var(--color-text);
}
form.form select {
    width: 100%;
}

form.form textarea,
textarea.textarea {
    border: var(--border-default);
    background: var(--color-white);
    border-radius: var(--border-radius-small);
    padding: var(--space-box-small);
    font-size: 1rem;
    width: 100%;
    color: var(--color-text);
}

textarea:focus,
input:focus,
button[type='submit'] {
    outline-color: var(--color-active-input);
}
