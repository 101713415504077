@import 'fonts';
@import 'variables';

html {
    font-size: var(--font-size-default);
}

body {
    font-family: var(--font-family);
    color: var(--color-text);
    line-height: 1.25em;
    /* 1.25rem; */
    background: var(--color-white);
}

*,
:after,
:before {
    box-sizing: border-box;
}

/* ::selection {
  background: var(--color-link);
} */

html,
body {
    height: 100%;
}
body {
    margin: 0;
    min-height: 100%;
}

a {
    color: var(--color-link);
}

.text-center {
    text-align: center;
}

.small-and-gentle-hint {
    font-size: 0.8rem;
    color: var(--color-grey);
    text-align: center;
    padding: var(--space-box);
}
